<template>
    <el-col ref='myInputCard' :md="5" :lg="4" :offset="nowOffset" class="col-box" @click.native="handleShowCard">
        <el-input
            :placeholder="cardname"
            :suffix-icon="!isShow ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"
            :value="inputValue"
        >
        </el-input>
        <el-card class="box-card" v-show="isShow" @click.native.stop>
            <div slot="header" class="clearfix">
                <span>选择{{cardname}}</span>
                <el-checkbox :value="allChecked" @change="handleAllCheck" style="float:right">全选</el-checkbox>
            </div>
            <div class="text-item">
                <el-checkbox-group v-model="checkboxGroup" size="small" class="flex-between flex-wrap">
                    <el-checkbox 
                    v-for="(item,index) in dataList" 
                    :label="item.name ? `${item.id}-${item.name}`:item.c_name" 
                    :key="index" 
                    :checked="item.checked"
                    border></el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="text-center sure-box">
                <el-button 
                size="medium" 
                class="margin-top margin-right cancelBtn"
                @click.native = "handleHideCard(false)"
                >取消</el-button>
                <el-button size="medium" type="primary" @click.native = "handleHideCard(true)">确定</el-button>
            </div>
        </el-card>
    </el-col>
</template>

<script>
    export default {
        props: {
            cardname: {
                type: String,
                default: '媒体'
            },
            dataname:{
                type:String,
            },
            colspan:{
                type:Number,
                default:4
            },
            nowOffset:{
                type:Number,
                default:0
            },
            dataList:{
                type:Array,
                default:()=>([])
            }
        },
        data() {
            return {
                isShow: false,
                content:'',
                checkboxGroup:[],
            }
        },
        computed:{
            allChecked(){//单选控制全选按钮由checked布尔值，全选控制单选只能通过更改checkboxGroup实现
                let totalLength = this.dataList.length
                let checkedLength = this.checkboxGroup.length
                return totalLength === checkedLength ? true : false
            },
            inputValue(){
                return this.content || this.cardname
            },
        },
        methods: {
            handleShowCard(){
                this.isShow = !this.isShow
            },
            handleHideCard(bool){
                this.isShow = false
                if(bool){
                    this.content = this.checkboxGroup.join('、')
                    this.$emit('passChecked',{title:this.dataname,value:this.checkboxGroup.slice()})
                } 
            },
            handleAllCheck(){
                let {dataList,allChecked} = this
                allChecked && this.checkboxGroup.splice(0,dataList.length+1)   
                dataList.forEach(item=>{
                    let title = item.name ? `${item.id}-${item.name}`:item.c_name
                    !allChecked //如果从非全选到全选状态，加入所有其中不存在的title
                    && !this.checkboxGroup.includes(title) 
                    && this.checkboxGroup.push(title)
                })
            }
        },
        created(){
            let inputCardDetail = sessionStorage.getItem('searchDate')
            if(inputCardDetail){
                let thisObj = JSON.parse(inputCardDetail)
                if(this.dataname === 'country'){
                    this.checkboxGroup = thisObj[this.dataname]
                }
                if(this.dataname === 'mediaId' || this.dataname === 'advplaceId'){
                    thisObj[this.dataname].forEach(item=>{
                        this.dataList.forEach(itm=>{
                            if(item == itm.id){
                                this.checkboxGroup.push(`${itm.id}-${itm.name}`)
                            }
                        })
                    })
                }
            }
            this.$emit('passChecked',{title:this.dataname,value:this.checkboxGroup.slice()})
            this.content = this.checkboxGroup.join('、')
        },
        mounted() {
            document.addEventListener('click',(e)=>{
                let outBox = this.$refs.myInputCard ? this.$refs.myInputCard.$el : null;
                if(outBox && !outBox.contains(e.target)) {
                    this.isShow = false
                }
            })
        },
    }
</script>

<style lang="less" scoped>
    .col-box{
        position:relative;
        .box-card{
            position: absolute;
            width:300px;
            top: 40px;
            left: 0;
            z-index: 99;
        }
    }
    .sure-box{
        margin-top:15px;
        border-top: 0.5px solid #eee;
    }
    .el-checkbox-group{
        max-height: 200px;
        overflow: auto;
    }
    .el-checkbox.is-bordered{
        display: block;
        margin: 8px 0 !important;
    }
    .cancelBtn{
        color:#222 !important;
    }
</style>