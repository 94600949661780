<template>
    <div class="paging" v-if='reShow'>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30]"
            :page-size="pageMsgCount"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            hide-on-single-page
            >
        </el-pagination>
    </div>
</template>

<script>
    export default {
        props:{
            total:{
                type:Number,
            },
            returnPage1:{
                type:Number,
            }
        },
        data() {
            return {
               currentPage:1,
               pageMsgCount:10,
               reShow:true
            }
        },
        watch:{
            returnPage1:{
                immediate:true,
                handler(){
                    this.currentPage = 1
                    this.pageMsgCount = 10
                    this.reShow = false
                    this.$nextTick(()=>{
                        this.reShow = true
                    })
                }
            }
        },
        methods: {
            handleSizeChange(val) {
                this.$emit('changeSize',val)
            },
            handleCurrentChange(val) {
                this.$emit('changePage',val)
            }
        },
        created(){
            let dataReportPage = JSON.parse(sessionStorage.getItem('dataReportPageRecord'))
            if(dataReportPage){
                this.currentPage = dataReportPage.page*1
                this.pageMsgCount = dataReportPage.pageSize*1
            }
        }
    }
</script>

<style lang="less" scoped>
    .paging{
        height: 72px;
        padding-right:60px;
        box-sizing: border-box;
        background: white;
        z-index:99;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
</style>